/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/loading/dist/style.css';
import '@ingka/zoom-image/dist/style.css';
import IcItems from '@ingka/ssr-icon/paths/copy';
import { Table, ConfigProvider } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import './UnitList.css';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '../../../../common/services/translationService.js';
import {
  isNullEmptyOrUndefined, projectType, userData,
} from '../../../../common/services/commonService.js';
import { useAppDispatch } from '../../../../store/hooks';
import useCallApi from '../../../../common/services/apiService.js';
import RenderEmpty from '../../../../common/components/renderEmpty/RenderEmpty';
import { setLoader, setToast } from '../../../../store/reducers/create-proj-reducer';
import { appRoutes } from '../../../../common/constants/constant';
import UnitImageModal from '../unitImageModal/UnitImageModal';

interface UnitListProps {
  headerHeight: number;
  searchResults: any[];
  handleTableChange: (pagination: any, filters: any, sorter: any, extra: any) => void;
}

export interface CustomUnit {
  key: React.Key,
  customUnitId:number,
  customUnitName:string,
  customUnitNo: string,
  customUnitType:string,
  imageCount: number,
  paNo: string,
  unitCurrency: string,
  unitRsp:number,
  unitRspDisplay:string
}

export const UnitList = ({
  headerHeight, searchResults, handleTableChange,
}: UnitListProps) => {
  const emptyText = useTranslate('find.unit.searchEmptyText');
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [imageToken, setImageToken] = useState<any>();
  const dispatch = useAppDispatch();
  const { post, get } = useCallApi();
  const navigate = useNavigate();

  const renderTableTitle = (title: string) => (
    <div>{title}</div>
  );

  const renderImage = (text: any) => {
    const isText = !isNullEmptyOrUndefined(text);
    return (
      isText ? (
        <div className={`table-number ${text !== 0 ? 'fcSearchResultsImage' : ''}`}>
          {text}
        </div>
      ) : (
        <div className="table-number">
          {text || 'N/A'}
        </div>
      )
    );
  };

  const fetchUnit = (record: any) => {
    dispatch(setLoader(true));
    const payload = {
      CustomUnitId: record.customUnitId,
      UserId: userData()?.userId,
      UserType: projectType(),
      ProjectType: projectType(),
    };

    post('get-custom-unit-details', payload, (data: any) => {
      navigate(appRoutes.unitDetails, {
        state: data,
      });
      dispatch(setLoader(false));
    }, () => {
      dispatch(setToast({ openToast: true, toastLabel: 'find.unit.failedToFetch' }));
      dispatch(setLoader(false));
    });
  };

  const getSasToken = () => {
    get('get-sas', setImageToken);
  };

  const getImages = (record: any) => {
    if (isNullEmptyOrUndefined(record.imageCount) || record.imageCount === 0) {
      fetchUnit(record);
    } else {
      dispatch(setLoader(true));
      getSasToken();
      const payload = {
        ComparisonId: 0,
        ItemNo: record.formatUnitNo,
        ProjectType: projectType(),
        ItemType: record.customUnitType,
      };

      post('get-photos', payload, (data: any) => {
        setSelectedItem(data);
        setOpenImageModal(true);
        dispatch(setLoader(false));
      }, () => {
        dispatch(setLoader(false));
      });
    }
  };

  const columns: ColumnsType<CustomUnit> = [
    {
      key: 1,
      title: renderTableTitle('PA'),
      dataIndex: 'paNo',
      sorter: false,
      width: '3%',
      render: (text: any) => <div className="table-number">{text || 'N/A'}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
      onCell: (record) => ({
        onClick: () => fetchUnit(record),
      }),
    },
    {
      key: 2,
      title: renderTableTitle('Item #'),
      dataIndex: 'customUnitNo',
      sorter: false,
      width: '9%',
      render: (text: any) => <div className={`${!isNullEmptyOrUndefined(text) ? 'itemNo-common-design' : ''}`}>{text || 'N/A'}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
      onCell: (record) => ({
        onClick: () => fetchUnit(record),
      }),
    },
    {
      key: 3,
      title: renderTableTitle('Item type'),
      dataIndex: 'customUnitType',
      sorter: false,
      width: '5%',
      render: (text: any) => <div>{text || 'N/A'}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
      onCell: (record) => ({
        onClick: () => fetchUnit(record),
      }),
    },
    {
      key: 4,
      title: renderTableTitle('Item name'),
      dataIndex: 'customUnitName',
      sorter: false,
      width: '16%',
      render: (text: any) => (
        <div data-testid="customUnitName">
          {text || 'N/A'}
        </div>
      ),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
      onCell: (record) => ({
        onClick: () => fetchUnit(record),
      }),
    },
    {
      key: 5,
      title: renderTableTitle(`${useTranslate('tab.items.image')}`),
      className: 'table-number',
      dataIndex: 'imageCount',
      sorter: false,
      width: '5%',
      render: (text: any) => (
        <div data-testid={`image-count-${text || 0}`}>
          {renderImage(text)}
        </div>
      ),
      onCell: (record) => ({
        onClick: () => getImages(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 5,
      title: renderTableTitle('Price'),
      dataIndex: 'unitRspDisplay',
      sorter: false,
      width: '8%',
      className: 'table-number',
      render: (text: any) => {
        if (text) {
          const [amount, currency] = text.split(' ');
          return (
            <div className="table-number">
              {`${currency} ${Number(amount).toFixed(2)}`}
            </div>
          );
        }
        return <div className="table-number">N/A</div>;
      },
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
      onCell: (record) => ({
        onClick: () => fetchUnit(record),
      }),
    },
  ];

  return (
    <div className="searchResultsWrapper" style={{ height: `calc(100vh - ${headerHeight}px)` }}>
      <div data-testid="row-count">
        {`Row count: ${searchResults.length}`}
      </div>
      <ConfigProvider renderEmpty={() => (
        <RenderEmpty
          empText={emptyText}
          icon={IcItems}
          subText
        />
      )}
      >
        <Table
          data-testid="unit-list-table"
          className={`table-striped-rows fcTable
           ${searchResults.length > 0 ? '' : 'fcBodyOverflow'}`}
          columns={columns}
          dataSource={searchResults}
          pagination={false}
          size="small"
          rowKey={(record: any) => record?.itemNo}
          onChange={handleTableChange}
          showHeader={searchResults?.length > 0}
          scroll={searchResults?.length > 0 ? { y: 'calc(100vh - 370px)' } : { y: '' }}
          footer={undefined}
        />
        <UnitImageModal
          openImageModal={openImageModal}
          imageToken={imageToken}
          imageList={selectedItem?.ikeaPhotosList}
          onCloseImageModal={() => setOpenImageModal(false)}
        />
      </ConfigProvider>
    </div>
  );
};
