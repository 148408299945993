/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Modal, {
  ModalBody, ModalHeader, Theatre,
} from '@ingka/modal';
import Carousel from '@ingka/carousel';
import Text from '@ingka/text';
import {
  Checkbox,
  ConfigProvider, TableProps,
  Form, Table, Typography,
} from 'antd';
import {
  SorterResult, SortOrder,
} from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import IcItems from '@ingka/ssr-icon/paths/copy';
import SortUpIcon from '@ingka/ssr-icon/paths/sort-ascending';
import IcPencil from '@ingka/ssr-icon/paths/pencil';
import SortDownIcon from '@ingka/ssr-icon/paths/sort-descending';
import SSRIcon from '@ingka/ssr-icon';
import FormField from '@ingka/form-field';
import Button from '@ingka/button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import PageHeader from '../../../common/components/header/Header';
import { useTranslate } from '../../../common/services/translationService.js';
import useCallApi from '../../../common/services/apiService.js';
import './QualityCheck.css';
import KnowMoreDialog from './knowmoreDialog/KnowmoreDialog';
import {
  isNullEmptyOrUndefined, projectType, userData, isIos, userRu,
  isNullEmptyUndefinedOrZero, isNullEmptyUndefinedOrZeroWithDot, isSu, convertUnit,
  roundQtyToTwoDecimalPlaces,
} from '../../../common/services/commonService.js';
import { setLoader, setToast } from '../../../store/reducers/create-proj-reducer';
import RenderEmpty from '../../../common/components/renderEmpty/RenderEmpty';
import Pagination from '../../../common/components/pagination/Pagination';
import PriceInput from '../../../common/components/inputs/PriceInput';
import NumberInput from '../../../common/components/inputs/NumberInput';
import QuantityInput from '../../../common/components/inputs/QuantityInput';
import {
  checkZeroRegex, compNoOfPieces, compPriceDiff, pieces,
} from '../../../common/constants/constant';
import { setQcCount } from '../../../store/reducers/dashboard-reducer';
import QcConnectItemPanel from './connectItemPanel/ConnectItemPanel';
import { Item } from '../createProject/items/Items';

interface QCItem {
  isItemConnected: boolean;
  userId: string;
  itemType: string;
  key: string;
  paNo: string;
  itemNumber: string;
  competitorName: string;
  ikeaItemPriceLocal: number;
  competitorItemPriceLocal: number;
  ikeaMultipack: number;
  competitorMultipack: number;
  photos: string;
  projectEndDate: string;
  comparedOn: string;
  comparisonRemovedIn: number;
  projectId: number;
  comparisonProjectid: number;
  isFoodHfb: boolean,
  ikeaPriceUnitText: string,
  ikeaPriceUnitValue: number,
  ikeaNoOfUnits: string,
  compPriceUnitText: string,
  compPriceUnitValue: number,
  compNoOfUnits: string,
  comparisonImages: string[] | null,
  ruCode: string,
  suCode: string,
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number';
  record: QCItem;
  index: number;
  children: React.ReactNode;
  isDelete: boolean;
  isPublish: boolean;
  inputDisable: boolean;
  compQuantity: number,
  compPrice: string,
  compUnit: string,
  dataChange: (e: any, type: string, record: any) => void;
}

const isNumeric = (val: any) => /^\d+$/.test(val);

const checkCompFieldsValidation = (val: any) => {
  const isDecimal = val?.toString().split('.')[1]?.length > 0;
  if (!isDecimal) {
    return isNumeric(val);
  }
  return (isDecimal || ((val - Math.floor(val)) !== 0));
};

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  children,
  isPublish,
  isDelete,
  inputDisable,
  title,
  compQuantity,
  compPrice,
  compUnit,
  dataChange,
  ...restProps
}) => {
  const inputNode = title === 'compNoOfUnits' ? (
    <div>
      {compUnit === pieces.code ? (
        <FormField
          valid={!isNullEmptyUndefinedOrZero(compQuantity)}
          className="qcErr"
          shouldValidate
        >
          <NumberInput
            className="qcEditCell"
            id="compPiece"
            label=""
            zeroValidate
            value={compQuantity}
            charLimit={13}
            disabled={(isPublish || isDelete) && inputDisable}
            onChange={(e) => dataChange(e, title, restProps.record)}
            onBlur={() => null}
            unitSuffix={compUnit}
          />
        </FormField>
      ) : (
        <FormField
          valid={!isNullEmptyUndefinedOrZeroWithDot(compQuantity)
            && checkCompFieldsValidation(compQuantity)}
          className="qcErr"
          shouldValidate
        >
          <QuantityInput
            className="qcEditCell"
            id="compPiece"
            label=""
            value={compQuantity}
            charLimit={13}
            disabled={(isPublish || isDelete) && inputDisable}
            onChange={(e) => dataChange(e, title, restProps.record)}
            onBlur={() => null}
            unitSuffix={compUnit}
          />
        </FormField>
      )}
    </div>
  ) : (
    <FormField
      valid={!isNullEmptyUndefinedOrZeroWithDot(compPrice)
        && checkCompFieldsValidation(compPrice)}
      className="qcErr"
      shouldValidate
    >
      <PriceInput
        className="qcEditCell"
        id="compPrice"
        label=""
        value={compPrice}
        currency={null}
        charLimit={13}
        disabled={(isPublish || isDelete) && inputDisable}
        onChange={(e) => dataChange(e, title, restProps.record)}
        onBlur={() => null}
      />

    </FormField>
  );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const qcPayloadData = {
  ItemName: '',
  ItemNo: null,
  FromDate: '',
  ToDate: '',
  HfbNos: [],
  PraNos: [],
  PaNos: [],
  UserId: '',
  UnitCode: '',
  ProjectType: null,
  ItemQuality: null,
  ItemFunctionality: null,
  Photos: null,
  OnlinePrice: null,
  CompetitorIds: [],
  CompetitorUnitCodes: [],
  RuCodes: [],
  SuCodes: [],
  RocNos: [],
  SpNos: [],
  PcNos: [],
  StartWith: 0,
  TotalCount: 25,
  CurrentUserId: '',
  IsMobile: true,
  SortEntity: '',
  TypeOfSort: '',
  IsSuperAdmin: userData()?.isSuperAdmin,
  SellingPrice: [],
  RecSalesPrice: [],
  ProjectId: '',
};

const QualityCheck = () => {
  const location: any = useLocation();
  const [form] = Form.useForm();
  const [qcData, setQCData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [editingRow, setEditingRow] = useState<Partial<QCItem> & { key: React.Key } | null>(null);
  const [disableSave, setDisableSave] = useState(false);
  const [disableSaveInit, setDisableSaveInit] = useState(true);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [qcValid, setQcValid] = useState(false);
  const [publishDisable, setPublishDisable] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [inputDisable, setInputDisable] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [compQuantity, setCompQuantity] = useState<any>(0);
  const [compQuantityTemp, setCompQuantityTemp] = useState<any>(0);
  const [compUnit, setCompUnitVal] = useState<any>('');
  const [compPrice, setCompPrice] = useState<any>(0);
  const [ikeaQuantity, setIkeaQuantity] = useState(0);
  const [ikeaPrice, setIkeaPrice] = useState<number | null>(0);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [imageToken, setImageToken] = useState<any>();
  const [openKnowMoreModal, setKnowMoreModal] = useState<any>(false);
  const [qcPayload, setQcPayload] = useState(qcPayloadData);
  const [sortConfig, setSortConfig] = useState<{
    sortEntity: string, typeOfSort: SortOrder | undefined
  }>({ sortEntity: 'projectEndDate', typeOfSort: 'ascend' });
  const [paginationItemCount, setPaginationItemCount] = useState<number>(1);
  const [totalQcCount, setTotalQcCount] = useState<number>(0);
  const [triggerShowmore, setTriggerShowmore] = useState<boolean>(false);
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSorting, setIsSorting] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isEditCancel, setIsEditCancel] = useState<boolean>(false);
  const [compItemMetric, setCompItemMetric] = useState<string>();
  const [ikeaItemMetric, setIkeaItemMetric] = useState<string>('');
  const [isFoodHfb, setIsFoodHfb] = useState<boolean>(false);
  const currencyList = useAppSelector((state) => state?.createProjReducer?.currencyList);
  const isQcBtnClick = location.state?.isOwnComparison === true;
  const [ownComparison, setOwnComparison] = useState<boolean>(isQcBtnClick);
  const [openConnectItemPanel, setOpenConnectItemPanel] = useState<boolean>(false);
  const [comparisonObject, setComparisonObject] = useState<any>(null);
  const [connectedItem, setConnectedItem] = useState<Item | any | null>(null);
  const [isItemConnected, setIsItemConnected] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [ikeaNoItemPrice, setIkeaNoItemPrice] = useState<number | null>(0);
  const [ikeaNoItemQuantity, setIkeaNoItemQuantity] = useState<any>('');
  const [ikeaNoItemUnit, setIkeaNoItemUnit] = useState<string>('');
  const [ikeaNoItemIsfood, setIkeaNoItemIsfood] = useState<boolean>(false);
  const [ikeaNoItemFoodDetails, setIkeaNoItemFoodDetails] = useState<any>();
  const [itemAdded, setItemAdded] = useState<boolean>(false);
  const dontShowKnowMoreCheckBox = localStorage.getItem('dontShowKnowMoreCheckBox');
  const qcErr = useTranslate('error.qualityCheck');
  const emptyText = useTranslate('qualitycheck.emptyText');
  const qcUpdateErr = useTranslate('error.qualityCheckUpdate');
  const imgTitle = useTranslate('qualitycheck.ikeaImageTitle');
  const copyImgTitle = useTranslate('qualitycheck.compImageTitle');
  const switchText = useTranslate('qualitycheck.switchText');
  const switchValue = useTranslate('inst.comp.switchValue');
  const dispatch = useAppDispatch();
  const { post, get } = useCallApi();

  const getSasToken = () => {
    get('get-sas', setImageToken);
  };

  const onOpenKnowMoreModal = () => {
    setKnowMoreModal(true);
  };

  const toggleLoader = (showLoader: boolean) => {
    if (!triggerShowmore && paginationItemCount === 1) {
      dispatch(setLoader(showLoader));
    } else {
      setIsLoading(showLoader);
    }
  };

  const getSwitchValue = () => {
    if (!isSu()) {
      return (location.state?.isOwnComparison === true);
    }
    const toggleButtonValue: any = document?.getElementById('qcOwnComparison');
    return toggleButtonValue?.checked;
  };

  const getQualityCheckCount = () => {
    const qcCountPayload = {
      UserId: getSwitchValue() ? userData()?.userId : '',
      UnitCode: isIos() ? null : userRu(),
      ProjectType: projectType(),
      CurrentUserId: userData()?.userId,
    };
    post('qualitycheck-count', qcCountPayload, (res: any) => {
      setTotalQcCount(res.projectCount);
      getSwitchValue() && dispatch(setQcCount(res?.projectCount || 0));
      toggleLoader(false);
    }, () => {
      toggleLoader(false);
      setTriggerShowmore(false);
      dispatch(setToast({ openToast: true, toastLabel: qcErr }));
    });
  };

  const getQualityCheckData = () => {
    const payload: any = qcPayload;
    payload.UserId = getSwitchValue() ? userData()?.userId : '';
    payload.ProjectType = projectType();
    payload.CurrentUserId = userData()?.userId;
    payload.UnitCode = isIos() ? null : userRu();
    payload.IsSuperAdmin = userData()?.isSuperAdmin;
    if (paginationItemCount === 1) payload.TotalCount = 25;
    toggleLoader(true);
    post('get-qc-comp', payload, (qcRes: any) => {
      setQCData(qcRes);
      if (!dontShowKnowMoreCheckBox && pageLoad === false) { onOpenKnowMoreModal(); }
      setPageLoad(true);
      if (!isSorting || isDelete || isPublish) {
        getQualityCheckCount();
      } else {
        toggleLoader(false);
      }
    }, () => {
      toggleLoader(false);
      setTriggerShowmore(false);
      dispatch(setToast({ openToast: true, toastLabel: qcErr }));
    });
  };

  useEffect(() => {
    if (!isSu() || isNullEmptyOrUndefined(location.state?.isOwnComparison)) {
      getQualityCheckData();
    }
  }, [qcPayload]);

  useEffect(() => {
    if (isSu() && location.state?.isOwnComparison === true) {
      getQualityCheckData();
    }
  }, [qcPayload]);

  const resetCheckBox = () => {
    setInputDisable(false);
    setPublishDisable(false);
    setIsDelete(false);
    setIsPublish(false);
    setQcValid(false);
    setCompQuantity(0);
    setCompPrice(0);
  };

  useEffect(() => {
    setInputDisable(false);
    if (!isNullEmptyOrUndefined(compPrice) && !isNullEmptyUndefinedOrZero(compQuantity) && compQuantity !== '.'
      && !isNullEmptyOrUndefined(ikeaPrice) && !isNullEmptyUndefinedOrZero(ikeaQuantity) && isEdit === true) {
      let competitorPerPiece = 0;
      let ikeaPerPiece = 0;
      let diffPercentage = 0;
      if (isFoodHfb && (ikeaItemMetric !== compItemMetric)) {
        competitorPerPiece = (Number(compPrice) / Number(convertUnit(compItemMetric, compQuantity)));
        ikeaPerPiece = (Number(ikeaPrice) / Number(convertUnit(ikeaItemMetric, ikeaQuantity)));
      } else {
        competitorPerPiece = (Number(compPrice) / Number(compQuantity));
        ikeaPerPiece = (Number(ikeaPrice) / Number(ikeaQuantity));
      }
      if (!isNullEmptyUndefinedOrZero(ikeaPerPiece)) {
        diffPercentage = (((competitorPerPiece - ikeaPerPiece) / ikeaPerPiece) * 100);
      } else {
        diffPercentage = 0;
      }
      // checking the percentage diff with configured percentage
      if (compItemMetric === pieces.code && (compQuantity > compNoOfPieces)) {
        setIsPublish(false);
        setQcValid(false);
      } else if (diffPercentage > compPriceDiff) {
        setIsPublish(false);
        setQcValid(false);
      } else if (diffPercentage < -compPriceDiff) {
        setIsPublish(false);
        setQcValid(false);
      } else if (diffPercentage <= compPriceDiff && diffPercentage >= -compPriceDiff) {
        setIsPublish(true);
        setQcValid(true);
      }
    } else {
      setIsPublish(false);
      setQcValid(false);
    }
    if (checkZeroRegex.test(compQuantity) || checkZeroRegex.test(compPrice) || !checkCompFieldsValidation(compPrice) || !checkCompFieldsValidation(compQuantity)) {
      setDisableSave(true);
    } else setDisableSave(false);
  }, [compQuantity, compPrice, isItemConnected, compItemMetric, ikeaItemMetric, ikeaPrice]);

  const onCloseItemModal = () => {
    setOpenImageModal(false);
  };

  const dataChange = (e: any, type: string, record: any) => {
    setDisableSaveInit(false);
    setIsFoodHfb(record?.isItemConnected === false ? ikeaNoItemIsfood : record?.isFoodHfb);
    setCompItemMetric(record?.isItemConnected === false ? compUnit : record?.compPriceUnitText);
    setIkeaItemMetric(record?.ikeaPriceUnitText || ikeaNoItemUnit);
    if (e > 0 || (type === 'competitorItemPriceLocal' && e === '.') || (type === 'competitorItemPriceLocal' && e >= 0)
      || (type === 'compNoOfUnits' && e === '.') || (type === 'compNoOfUnits' && e >= 0)) {
      setIkeaQuantity(record?.isItemConnected === false ? ikeaNoItemQuantity : (record?.isFoodHfb && record?.compPriceUnitText !== pieces.code)
        ? record?.ikeaPriceUnitValue : record?.ikeaMultipack);
      setIkeaPrice(record?.ikeaItemPriceLocal || ikeaNoItemPrice);
      if (type === 'compNoOfUnits') {
        setCompQuantity(e);
        setCompQuantityTemp(e);
      } else {
        setCompPrice(e);
      }
    } else {
      setDisableSave(true);
      if (type === 'compNoOfUnits') {
        setCompQuantity('');
        setCompQuantityTemp('');
      } else setCompPrice('');
    }
    setIsEdit(true);
  };

  const carouselProps = {
    id: 'fcItemDetailsCarousel',
    hasNoControls: false,
    prefix: '',
    ariaLabelLeftBtn: 'See previous items',
    ariaLabelRightBtn: 'See next items',
    isSlideShow: true,
  };

  const getImages = (record: any) => {
    if (!isNullEmptyOrUndefined(record.photos) && record.photos > 0) {
      dispatch(setLoader(true));
      getSasToken();
      const payload = {
        ComparisonId: record.comparisonProjectid,
        ItemNo: record.itemNumber,
        ProjectType: record.projectType,
        ItemType: record.itemType,
      };
      post('get-photos', payload, (data: any) => {
        setSelectedItem(data);
        setOpenImageModal(true);
        dispatch(setLoader(false));
      }, () => {
        dispatch(setLoader(false));
      });
    }
  };

  const getCompetitorQuantity = (val: any) => {
    if (val?.isFoodHfb === true) {
      if (isNullEmptyOrUndefined(val?.compPriceUnitValue)) {
        return val?.competitorMultipack;
      }
      return val?.compPriceUnitValue;
    } if (val.compPriceUnitText !== pieces.code) {
      return val?.compPriceUnitValue;
    }
    return val?.competitorMultipack;
  };

  const addOrRemoveConnectedItem = (item: any, record: any) => {
    const qcDataList = JSON.parse(JSON.stringify(qcData));
    const itemIndex = qcDataList.findIndex(((obj: any) => obj.comparisonProjectid === record.comparisonProjectid));
    if (!isNullEmptyOrUndefined(item)) {
      setItemAdded(true);
      const updatedComp = {
        ...qcDataList[itemIndex],
        paNo: item.paNo,
        itemNumber: item.itemNo,
        itemType: item.itemType,
        itemName: item.itemName,
        ikeaItemPriceLocal: item.ikeaItemPriceLocal,
        ikeaMultipack: item.ikeaMultipack,
        ikeaPriceUnitText: item.ikeaPriceUnitText,
        ikeaPriceUnitValue: item.ikeaPriceUnitValue,
        ikeaNoOfUnits: item.ikeaNoOfUnits,
      };
      setDisableSaveInit(false);
      setIsItemConnected(true);
      setPublishDisable(false);
      qcDataList[itemIndex] = updatedComp;
    } else {
      setItemAdded(false);
      const updatedComp = {
        ...qcDataList[itemIndex],
        paNo: null,
        itemNumber: '',
        itemType: null,
        itemName: null,
        ikeaItemPriceLocal: null,
        ikeaMultipack: null,
        ikeaPriceUnitText: null,
        ikeaPriceUnitValue: null,
        ikeaNoOfUnits: null,
      };
      setIsItemConnected(false);
      setIkeaNoItemPrice(null);
      setIkeaNoItemQuantity('');
      setIkeaNoItemUnit('');
      setPublishDisable(true);
      qcDataList[itemIndex] = updatedComp;
    }
    setQCData(qcDataList);
    setConnectedItem(item);
  };

  const isEditing = (record: QCItem) => record?.comparisonProjectid?.toString() === editingKey;

  /**
   * Edit function that sets the editing row, disables save button, and updates form fields.
   * @param record - The record to be edited.
   * @param type - Optional parameter to specify the type of edit.
   */
  const edit = (record: Partial<QCItem> & { key: React.Key }, type?: boolean) => {
    setEditingRow(record);
    setDisableSaveInit(true);
    setIsEdit(false);
    setIsCancel(false);
    isNullEmptyOrUndefined(record.itemNumber) ? setPublishDisable(true) : setPublishDisable(false);
    form.setFieldsValue({
      competitorItemPriceLocal: '', competitorMultipack: '', ...record,
    });
    if (type !== true && isEditCancel === false) {
      setIsEditCancel(true);
      setCompQuantity(getCompetitorQuantity(record) || 0);
      setCompQuantityTemp(getCompetitorQuantity(record) || 0);
      setCompUnitVal(record?.compPriceUnitText);
      setCompPrice(record?.competitorItemPriceLocal || 0);
      setIkeaItemMetric(record?.ikeaPriceUnitText || '');
      setCompItemMetric(record?.compPriceUnitText || '');
      !isNullEmptyOrUndefined(record.itemNumber) && setIsItemConnected(true);
    }
    setEditingKey(record?.comparisonProjectid?.toString() || '');
  };

  const cancel = (record: any) => {
    setEditingRow(null);
    setIsCancel(true);
    setIsEditCancel(false);
    setCompQuantityTemp(0);
    resetCheckBox();
    setEditingKey('');
    setIsEdit(false);
    setIsItemConnected(false);
    setIkeaNoItemPrice(0);
    setIkeaNoItemQuantity(0);
    setIkeaNoItemUnit('');
    setIsFoodHfb(false);
    setItemAdded(false);
    setCompUnitVal('');
    if (record?.isItemConnected === false) {
      addOrRemoveConnectedItem(null, record);
    }
  };

  const setIkeaQuntnUnit = (ikeaQuant: number, ikeaUnit: string) => {
    setIkeaNoItemQuantity(ikeaQuant);
    setIkeaNoItemUnit(ikeaUnit);
  };

  const setPiecesOrNot = (record: any) => {
    if (((record?.isItemConnected === true && record?.compPriceUnitText !== pieces.code)
      || (record?.isItemConnected === false && compUnit !== pieces.code))) return true;
    return false;
  };

  const bulkUpdateAPI = (payload: any, record: any) => {
    post('bulkupdate-comparison', payload, () => {
      getQualityCheckData();
      cancel(record);
    }, () => {
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: qcUpdateErr }));
    });
  };

  const buildRuCode = (record: any) => {
    if (isSu()) {
      return localStorage.getItem('RuCodeForSu') || '';
    }
    return record?.ruCode as any || '';
  };

  const save = async (record: QCItem) => {
    try {
      setEditingRow(record);
      dispatch(setLoader(true));
      const row = (await form.validateFields()) as QCItem;
      row.compNoOfUnits = compQuantity; // Use the updated state value
      row.competitorItemPriceLocal = compPrice; // Use the updated state value
      const currencyCode = currencyList.find((item: any) => item.key === userRu());
      const isFoodItem = isFoodHfb;
      const imageList = record?.comparisonImages || [];
      let noOfPiece = 0;
      if (row.compNoOfUnits === record.compNoOfUnits) {
        noOfPiece = (isFoodItem && setPiecesOrNot(record) === true) ? record?.compPriceUnitValue : record?.competitorMultipack;
      } else { noOfPiece = Number(row.compNoOfUnits); }
      let updateQcPayload: any = [{
        itemNo: record?.itemNumber || null,
        itemType: record?.itemType || null,
        PaNo: record?.paNo || null,
        PriceUnitTypeNo: (record?.isItemConnected === false && isFoodHfb === true) ? ikeaNoItemFoodDetails?.priceUnit?.content[0]?.priceUnitTypeNo : null,
        PriceUnitType: (record?.isItemConnected === false && isFoodHfb === true) ? ikeaNoItemFoodDetails?.priceUnit?.content[0]?.priceUnitTypeName : null,
        IkeaLocalPrice: record?.isItemConnected === false ? ikeaPrice : null,
        UpdatedUserId: record?.userId || null,
        comparisonId: record.comparisonProjectid,
        competitorPrice: row.competitorItemPriceLocal.toString(),
        noOfPieces: ((record?.isItemConnected === true && record?.compPriceUnitText === pieces.code)
          || (record?.isItemConnected === false && compUnit === pieces.code)) ? Number(noOfPiece) : null,
        isDeleted: isDelete,
        isPublish: qcValid === true ? false : isPublish,
        CurrencyCode: currencyCode?.value || null,
        isFoodHfb: isFoodItem === true ? 1 : 0,
        IkeaPriceUnitValue: setPiecesOrNot(record) === true
          ? (isFoodItem === true ? Number(record?.ikeaPriceUnitValue) || Number(ikeaQuantity)
            : Number(record?.ikeaMultipack) || null) : null,
        CompPriceUnitValue: setPiecesOrNot(record) === true ? (Number(noOfPiece) || null) : null,
        CompPriceUnitText: setPiecesOrNot(record) === true ? (compItemMetric || null) : null,
        IkeaPriceUnitText: setPiecesOrNot(record) === true ? (ikeaItemMetric || null) : null,
      }];

      if (itemAdded && imageList?.length > 0) {
        const formData = new FormData();
        imageList.forEach((image: any) => {
          formData.append('existingImages', image);
        });
        const itemName = connectedItem?.itemName?.replaceAll('/', '');
        const ItemNameTrimmed = itemName?.replace(/\s+/g, '');
        formData.append('itemName', ItemNameTrimmed || '');
        formData.append('competetorName', record?.competitorName as any);
        formData.append('retailUnit', buildRuCode(record));
        dispatch(setLoader(true));
        post('upload-image', formData, (res: []) => {
          const errorFiles = res.filter((file: any) => file?.status?.toString()?.toLowerCase() === 'fail');
          if (errorFiles?.length >= 1) {
            dispatch(setLoader(false));
            dispatch(setToast({ openToast: true, toastLabel: 'Something went wrong' }));
          } else {
            const imgData = res.map((val: any) => val.fileName);
            updateQcPayload = [{ ...updateQcPayload[0], ComparisonImages: imgData }];
            bulkUpdateAPI(updateQcPayload, record);
          }
        });
      } else {
        bulkUpdateAPI(updateQcPayload, record);
      }
    } catch (errInfo) {
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    if (paginationItemCount > 1) {
      setQcPayload({ ...qcPayload, TotalCount: (paginationItemCount * 25) });
    }
  }, [paginationItemCount]);

  useEffect(() => {
    setIsFoodHfb(ikeaNoItemIsfood);
    setIkeaItemMetric(ikeaNoItemUnit);
    setIkeaPrice(ikeaNoItemPrice);
    setIkeaQuantity(ikeaNoItemQuantity);
    setCompItemMetric(compUnit);
    setIsEdit(true);
  }, [ikeaNoItemIsfood, ikeaNoItemPrice, ikeaNoItemQuantity, ikeaNoItemUnit, compUnit]);

  const showMoreContent = () => {
    setTriggerShowmore(true);
    setIsSorting(true);
    setPaginationItemCount(paginationItemCount + 1);
  };

  const setCompNoItemUnitVal = (val: string) => {
    setCompUnitVal(val);
    if (compQuantity?.toString().length !== 0 && val === pieces.code && compQuantity?.toString().includes('.')) {
      setCompQuantity('');
    } else if (val === pieces.code && compQuantityTemp.toString().includes('.')) {
      setCompQuantity(compQuantity || 0);
    } else {
      setCompQuantity(compQuantityTemp || 0);
    }
  };

  const renderPagination = () => {
    const count = qcData.length;
    const totalCount = totalQcCount;
    return (
      <Pagination
        count={count}
        isLoading={isLoading}
        totalCount={totalCount}
        showMoreContent={showMoreContent}
      />
    );
  };

  const handleTableChange: TableProps<QCItem>['onChange'] = (pagination, filters, sorter) => {
    const sortEntity = ((sorter as SorterResult<QCItem>).field) as string;
    const sortTypeParam = (sorter as SorterResult<QCItem>).order;
    const typeOfSort = (!sortTypeParam || sortTypeParam === 'ascend') ? 'asc' : 'desc';
    setSortConfig({ sortEntity, typeOfSort: (sorter as SorterResult<QCItem>).order || 'ascend' });
    setTriggerShowmore(false);
    setIsSorting(true);
    setPaginationItemCount(1);
    setQcPayload({
      ...qcPayload,
      SortEntity: sortEntity === 'comparisonRemovedIn' ? 'comparison_deleted_on' : sortEntity,
      TypeOfSort: typeOfSort,
      TotalCount: 25,
    });
  };

  const getSortOrder = (dataIndex: string) => {
    if (sortConfig.sortEntity === dataIndex) {
      return sortConfig.typeOfSort;
    }
    return null;
  };

  const renderTableTitle = (title: string, dataIndex: string) => {
    const isActive = dataIndex === sortConfig.sortEntity;
    return (
      isActive ? (
        <div style={{ display: 'flex' }}>
          {title}
          {sortConfig.typeOfSort === 'ascend' ? (
            <SSRIcon className="sortIcon" paths={SortUpIcon} />
          ) : (
            <SSRIcon className="sortIcon" paths={SortDownIcon} />
          )}
        </div>
      ) : (
        <div>{title}</div>
      )
    );
  };

  const renderDate = (text: any) => {
    const isText = !isNullEmptyOrUndefined(text);
    return (
      isText ? (
        <div>{dayjs(text).format('YYYY-MM-DD')}</div>
      ) : (
        <div>{text || 'N/A'}</div>
      )
    );
  };

  /**
   * Renders the IKEA price for a given QCItem.
   * If the record is being edited, it renders the IKEA sales price.
   * For other rows, it displays "N/A" if the price is null.
   *
   * @param record - The QCItem to render the price for.
   * @returns The JSX element representing the IKEA price.
   */
  const renderIkeaPrice = (record: QCItem) => {
    // Get currency code
    const currencyCode = currencyList.find((item: any) => item.key === userRu())?.value || '';

    if (isEditing(record)) {
      // Render the IKEA sales price only for the editing row
      const price = record.ikeaItemPriceLocal || ikeaNoItemPrice;
      return <div>{price ? `${currencyCode} ${price.toFixed(2)}` : 'N/A'}</div>;
    }
    // For other rows, display "N/A" if the price is null
    return <div>{record.ikeaItemPriceLocal === null ? 'N/A' : `${currencyCode} ${record.ikeaItemPriceLocal.toFixed(2)}`}</div>;
  };

  const renderImage = (text: any) => {
    const isText = !isNullEmptyOrUndefined(text);
    return (
      isText ? (
        <div className={`table-number qcCompImageRight ${text !== 0 ? 'qcSearchResultsImage' : ''}`}>
          {text}
        </div>
      ) : (
        <div className="table-number qcCompImageRight">
          {text || '0'}
        </div>
      )
    );
  };

  /**
   * Renders the quantity and units for IKEA items.
   *
   * @param text - The text to be rendered.
   * @param record - The record containing the data.
   * @returns The JSX element representing the quantity and units for IKEA items.
   */
  const renderIKEAQuantityNUnits = (text: any, record: any) => {
    if (isEditing(record)) {
      // Render the IKEA quantity and unit only for the editing row
      return (
        <div>{text || ikeaNoItemQuantity + ikeaNoItemUnit || 'N/A'}</div>
      );
    }

    // For other rows, display "N/A" if the quantity and unit are null
    return (
      <div>
        {!isNullEmptyOrUndefined(text) ? roundQtyToTwoDecimalPlaces(text) : 'N/A'}
      </div>
    );
  };

  const renderCompetitorQuantityNUnits = (text: any, record: any) => {
    const compValues = getCompetitorQuantity(record);
    const isValidText = !isNullEmptyOrUndefined(compValues);
    return (
      isValidText ? (
        <div>{roundQtyToTwoDecimalPlaces(text)}</div>
      ) : (
        <div>N/A</div>
      )
    );
  };

  useEffect(() => {
    const qcTablePagination = document.querySelector<HTMLElement>('.paginationWrapper');
    // table onload pagination hide
    if (qcTablePagination) {
      qcTablePagination.style.display = 'none';
    }
  }, [totalQcCount, qcData]);

  useEffect(() => {
    const qcTable = document.querySelector<HTMLElement>('.ant-table-body');
    if (qcTable) {
      qcTable.addEventListener('scroll', () => {
        // eslint-disable-next-line max-len
        const scrollPercentage = (qcTable.scrollTop / (qcTable.scrollHeight - qcTable.clientHeight)) * 100;
        if (scrollPercentage <= 99) {
          (document.getElementsByClassName('paginationWrapper') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'none';
        }
        if (scrollPercentage >= 99) {
          (document.getElementsByClassName('paginationWrapper') as HTMLCollectionOf<HTMLElement>)[0].style.display = '';
        }
      });
    }
  }, []);

  const handleCheckBox = (e: any, type: string) => {
    setDisableSaveInit(false);
    setInputDisable(true);
    if (type === 'publish') { setIsPublish(e.target.checked); } else setIsDelete(e.target.checked);
  };

  const renderItemNumber = (text: any) => (!isNullEmptyOrUndefined(text) ? (
    <div className={`${!editingKey ? 'itemNo-common-design' : 'qc-itemNo'}`}>{text}</div>
  ) : (
    <div>--</div>
  ));

  const toggleConnectItemPanel = (record?: any, type?: boolean) => {
    if (openConnectItemPanel === false) {
      setComparisonObject(record);
      edit(record, type);
    }
    setOpenConnectItemPanel(!openConnectItemPanel);
  };

  /**
 * Function for rendering the name column.
 * It disables the 'Add' button by default and enables it only when the row is being edited.
 *
 * @param text - The text to be rendered in the name column.
 * @param record - The record associated with the row.
 * @returns JSX element.
 */

  const renderName = (text: any, record: any) => (
    <div>
      {record?.isItemConnected === false ? (
        !isNullEmptyOrUndefined(text) ? (
          <div className="qcNameWrapper-newItem">
            <Text>{text}</Text>
            <Button
              className="qcName-pencil"
              type="plain"
              ssrIcon={IcPencil}
              onClick={() => toggleConnectItemPanel(record, true)}
            />
          </div>
        ) : (
          <Button
            className="qcName-add-button"
            type="plain"
            text="Add"
            onClick={() => toggleConnectItemPanel(record)}
            disabled={editingRow?.comparisonProjectid !== record.comparisonProjectid}
          />
        )
      ) : (
        text || 'N/A'
      )}
    </div>
  );

  const columns = [
    {
      title: renderTableTitle('PA', 'paNo'),
      dataIndex: 'paNo',
      width: '5%',
      editable: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('paNo'),
      render: (text: any) => <div className="table-number">{text || '--'}</div>,
    },
    {
      title: renderTableTitle('Item #', 'itemNumber'),
      dataIndex: 'itemNumber',
      width: editingKey ? '7%' : '9%',
      editable: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('itemNumber'),
      render: (text: any) => renderItemNumber(text),
    },
    {
      title: renderTableTitle('Item name', 'itemName'),
      dataIndex: 'itemName',
      width: editingKey ? '13%' : '20%',
      editable: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('itemName'),
      render: (text: any, record: any) => renderName(text, record),
    },
    {
      title: renderTableTitle('Competitor', 'competitorName'),
      dataIndex: 'competitorName',
      width: editingKey ? '7%' : '10%',
      editable: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('competitorName'),
      render: (text: any) => <div>{text || 'N/A'}</div>,
    },
    {
      title: renderTableTitle('IKEA sales price', 'ikeaItemPriceLocal'),
      dataIndex: 'ikeaItemPriceLocal',
      className: 'table-number',
      width: '6%',
      editable: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('ikeaItemPriceLocal'),
      render: (text: any, record: QCItem) => renderIkeaPrice(record),
    },
    {
      title: renderTableTitle('Competitor price', 'competitorItemPriceLocal'),
      dataIndex: 'competitorItemPriceLocal',
      className: 'table-number',
      width: editingKey ? '9%' : '8%',
      editable: true,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('competitorItemPriceLocal'),
      render: (text: any) => {
        const currencyCode = currencyList.find((item: any) => item.key === userRu())?.value || '';
        const formattedValue = text ? Number(text).toFixed(2) : 'N/A';
        return <div title="">{text ? `${currencyCode} ${formattedValue}` : 'N/A'}</div>;
      },
    },
    {
      title: renderTableTitle('IKEA quantity & unit', 'ikeaNoOfUnits'),
      className: 'table-number',
      dataIndex: 'ikeaNoOfUnits',
      width: '8%',
      editable: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('ikeaNoOfUnits'),
      render: (text: any, record: any) => renderIKEAQuantityNUnits(text, record),
    },
    {
      title: renderTableTitle('Competitor quantity & unit', 'compNoOfUnits'),
      className: 'table-number',
      dataIndex: 'compNoOfUnits',
      width: '9%',
      editable: true,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('compNoOfUnits'),
      render: (text: any, record: any) => renderCompetitorQuantityNUnits(text, record),
    },
    {
      title: renderTableTitle('Image', 'photos'),
      dataIndex: 'photos',
      className: 'table-number',
      width: editingKey ? '4%' : '6%',
      editable: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('photos'),
      render: (text: any) => renderImage(text),
      onCell: (record: any) => ({
        onClick: () => getImages(record),
      }),
    },
    {
      title: renderTableTitle('Project end date', 'projectEndDate'),
      dataIndex: 'projectEndDate',
      width: editingKey ? '8%' : '10%',
      editable: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('projectEndDate'),
      render: (text: any) => renderDate(text),
    },
    {
      title: renderTableTitle('Compared on', 'comparedOn'),
      dataIndex: 'comparedOn',
      width: editingKey ? '8%' : '10%',
      editable: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('comparedOn'),
      render: (text: any) => renderDate(text),
    },
    {
      title: renderTableTitle('Comparison removed in', 'comparisonRemovedIn'),
      dataIndex: 'comparisonRemovedIn',
      className: 'qcPadding',
      width: '6%',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('comparisonRemovedIn'),
      render: (_: any, record: QCItem) => (
        <span>
          {record.comparisonRemovedIn}
          {' '}
          Day(s)
        </span>
      ),
      editable: false,
    },
    {
      title: editingKey ? 'Delete | Publish | Actions' : 'Actions',
      dataIndex: 'operation',
      width: editingKey ? '17%' : '9%',
      className: editingKey ? '' : 'table-text',
      render: (_: any, record: QCItem) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="qc_flexbox" style={{ gap: '20px' }}>
            <Checkbox disabled={isPublish} onClick={(e) => handleCheckBox(e, 'delete')} />
            <Checkbox disabled={isDelete || qcValid || publishDisable} checked={isPublish} onClick={(e) => handleCheckBox(e, 'publish')} />
            <div>
              <Typography.Link
                onClick={() => save(record)}
                style={{ marginRight: 12 }}
                role="button"
                disabled={disableSave === true || disableSaveInit === true}
              >
                Save
              </Typography.Link>
              <Typography.Link onClick={() => cancel(record)} style={{ marginRight: 4 }}>
                Cancel
              </Typography.Link>
            </div>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: QCItem) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.dataIndex,
        editing: isEditing(record),
        isPublish,
        isDelete,
        inputDisable,
        compQuantity,
        compPrice,
        dataChange,
        compUnit,
      }),
    };
  });

  return (
    <div style={{ marginTop: '60px' }}>
      <PageHeader
        isEnable
        title={useTranslate('qualitycheck.pageTitle')}
        subTitle={useTranslate('qualitycheck.pageSubTitle')}
        subLink={useTranslate('qualitycheck.pageSubTitleLink')}
        buttonLabel={useTranslate('qualitycheck.editButton')}
        totalQcCount={totalQcCount}
        openKnowMoreModal={() => onOpenKnowMoreModal()}
        switchText={switchText}
        switchValue={switchValue}
        showMyComparisonBtn={!!isSu()}
        ownComparison={ownComparison}
        setOwnComparison={setOwnComparison}
        setPaginationItemCount={setPaginationItemCount}
        setIsSorting={setIsSorting}
        setTriggerShowmore={setTriggerShowmore}
        getQualityCheckData={() => getQualityCheckData()}
      />
      <Modal
        visible={openImageModal}
        handleCloseBtn={() => onCloseItemModal()}
      >
        <Theatre
          aria-labelledby="qcImageDetailsModal"
          className="qcImageDetails-theatre"
          header={(
            <ModalHeader
              className="defaultFont"
              titleId="qcImageDetailsModal"
              title="Images"
              floating={false}
            />
          )}
        >
          <ModalBody>
            <div className="qcImgeContainer">
              <div className="qcIkeaImage">
                <Text className="qcImageTitleText">{imgTitle}</Text>
                <Carousel {...carouselProps}>
                  {selectedItem?.ikeaPhotosList?.map(
                    (item: any) => (
                      <img
                        key={item?.imagePath}
                        className="qcItemDetails-item-image"
                        src={item?.imagePath}
                        alt=""
                      />
                    ),
                  )}
                </Carousel>
              </div>
              <div className="qcCompImage">
                <Text className="qcImageTitleText">{copyImgTitle}</Text>
                <Carousel {...carouselProps}>
                  {selectedItem?.competitorPhotosList?.map(
                    (item: any) => (
                      <img
                        key={item?.imagePath}
                        className="qcItemDetails-item-image"
                        src={item?.imagePath.concat(imageToken?.sasToken)}
                        alt=""
                      />
                    ),
                  )}
                </Carousel>
              </div>
            </div>
          </ModalBody>
        </Theatre>
      </Modal>
      <QcConnectItemPanel
        isVisible={openConnectItemPanel}
        isCancel={isCancel}
        connectedItem={connectedItem}
        comparisonObject={comparisonObject}
        onClose={toggleConnectItemPanel}
        setIkeaQuntnUnit={setIkeaQuntnUnit}
        compUnit={compUnit}
        setIkeaPrice={setIkeaNoItemPrice}
        setCompUnit={setCompNoItemUnitVal}
        setIkeaIsfood={setIkeaNoItemIsfood}
        setIkeaFoodDetails={setIkeaNoItemFoodDetails}
        setItem={(item, record) => {
          addOrRemoveConnectedItem(item, record);
        }}
      />
      <KnowMoreDialog
        isVisible={openKnowMoreModal}
        onClose={() => setKnowMoreModal(false)}
        onContinue={() => setKnowMoreModal(false)}
      />
      <div className={`${isSu() ? 'qcContentWrapper qcSuTopWrapper' : 'qcContentWrapper'}`}>
        <ConfigProvider renderEmpty={() => (
          <RenderEmpty
            empText={emptyText}
            icon={IcItems}
          />
        )}
        >
          <Form form={form} component={false} initialValues={qcData}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              size="small"
              rowKey={(record: any) => record.comparisonProjectid}
              className={`${isSu() ? 'table-striped-rows qcSuTable' : 'table-striped-rows qcTable'}`}
              dataSource={qcData}
              columns={mergedColumns as any}
              rowClassName="editable-row"
              pagination={false}
              onChange={handleTableChange}
              showHeader={qcData?.length > 0}
              scroll={qcData?.length > 0 ? { y: 'calc(100vh - 210px)' } : { y: '' }}
              footer={undefined}
            />
            {qcData.length > 0 && totalQcCount > 25
              && renderPagination()}
          </Form>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default QualityCheck;
