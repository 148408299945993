/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import SSRIcon from '@ingka/ssr-icon';
import IcPerson from '@ingka/ssr-icon/paths/person';
import Bell from '@ingka/ssr-icon/paths/bell';
import { useAuth0 } from '@auth0/auth0-react';
import Hamburger from '../hamburger/Hamburger';
import classes from './AppBar.module.css';
import defaultClasses from '../../../App.module.css';
import ikeaLogo from '../../../assets/images/ic_ikea_logo.svg';
import { useTranslate } from '../../services/translationService.js';
import {
  isIos,
  isNullEmptyOrUndefined,
  isRu,
  isSu,
  notificationUrls,
  projectType,
  userData,
  userRu,
} from '../../services/commonService.js';
import useCallApi from '../../services/apiService.js';
import { appRoutes, userTypesData } from '../../constants/constant';
import SettingsPanel from '../settingsPanel/SettingsPanel';
import { offlineData } from '../../configs/offlineData';
import { useAppSelector } from '../../../store/hooks';
import NotificationWidget from '../notificationWidget/NotificationWidget';
import NotificationPopup from '../notificationPopup/NotificationPopup';

const AppBar = () => {
  const location = useLocation();
  const [openSettingsPanel, setOpenSettingsPanel] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [newNotificationHighlight, setNewNotificationHighlight] = useState(false);
  const [openNotificationPopup, setOpenNotificationPopup] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationMediaToken, setNotificationMediaToken] = useState('');
  const [notificationPopupData, setNotificationPopupData] = useState([]);
  const [showSuNewTag, setShowSuNewTag] = useState(false);
  const [appVersion, setAppVersion] = useState('');
  const [notifWidgetLoader, setNotifWidgetLoader] = useState(false);
  const [notifPopupLoader, setNotifPopupLoader] = useState(false);

  const [unit, setUnit] = useState('');
  const applicationTitle = useTranslate('application.title');
  const qualityIssues = useTranslate('quality.issues');
  const { get, post, put } = useCallApi();
  const navigate = useNavigate();
  const qualityCheckCount = useAppSelector((store) => store.dashboardReducer.qcCount);
  const isQcScreen = location?.pathname === appRoutes?.qualityCheck;
  const { isAuthenticated } = useAuth0();

  const handleLogoClick = () => {
    navigate(appRoutes.dashboard);
  };

  const fetchUnit = (sellingUnit?: string) => {
    offlineData.getItem('selectedRetailUnit').then((selectedRu: any) => {
      if (isIos()) {
        setUnit('IKEA of Sweden');
      }
      if (!isNullEmptyOrUndefined(selectedRu) && isRu()) {
        setUnit(`Retail Unit: ${selectedRu?.ruName}`);
      }
      if (!isNullEmptyOrUndefined(selectedRu) && isSu()) {
        setUnit(`Selling Unit: ${selectedRu?.ruCode} - ${sellingUnit}`);
      }
    });
  };

  const getSellingUnit = (data:any) => {
    offlineData.getItem('userRetailUnits').then((userRetailData: any) => {
      const userSuName = data.filter((val:any) => val.unitCode === userRetailData[0]);
      fetchUnit(userSuName[0]?.unitName);
    });
  };

  const fetchVersion = () => {
    get('enum-lookups', (data: any) => {
      const versionObj = data.find((item: any) => item.key === 'RELEASE_VERSION');
      setAppVersion(versionObj.value);
    });
  };

  const qualityClick = () => {
    navigate(appRoutes.qualityCheck, { state: { isOwnComparison: true } });
  };

  useEffect(() => {
    isSu() && get('selling-units', getSellingUnit);
    !isSu() && fetchUnit();
    fetchVersion();
  }, [openSettingsPanel]);

  const getQualityCheckCount = () => {
    const qcCountPayload = {
      UserId: userData()?.userId,
      UnitCode: userRu(),
      ProjectType: projectType(),
      CurrentUserId: userData()?.userId,
    };
    post('qualitycheck-count', qcCountPayload, (res: any) => {
      if (res?.projectCount > 0) {
        setShowSuNewTag(true);
      }
    }, () => null);
  };

  useEffect(() => {
    if (isSu()) {
      getQualityCheckCount();
    }
  }, []);

  const notifMediaToken = () => {
    get(notificationUrls().notifMediaToken, (res:any) => setNotificationMediaToken(res));
  };

  useEffect(() => {
    if (isMobile === false) {
      notifMediaToken();
      setTimeout(() => {
        get(notificationUrls().popupDisplay, (res:any) => {
          if (res === true) {
            setNotifPopupLoader(true);
            get(notificationUrls().notificationPopup, (data: any) => {
              setNotifPopupLoader(false);
              const notifFilterData = data?.filter(
                (val: any) => !(val.isRepeating === false && val.isPopupRead === true),
              );
              !isNullEmptyOrUndefined(notifFilterData) && setOpenNotificationPopup(true);
              setNotificationPopupData(notifFilterData || []);
            }, () => setNotifPopupLoader(false));
          }
        }, () => {
          setNotifPopupLoader(false);
        });
        get(notificationUrls().notificationHighlight, setNewNotificationHighlight);
      }, 6000);
    }
  }, []);

  const toggleSettings = () => {
    setOpenSettingsPanel(!openSettingsPanel);
  };

  const toggleNotification = (val: boolean) => {
    if (val === true) {
      setNotifWidgetLoader(true);
      setNotificationData([]);
      notifMediaToken();
      setTimeout(() => {
        get(notificationUrls().notificationSideBar, (res: any) => {
          setNotifWidgetLoader(false);
          setNotificationData(res || []);
        }, () => setNotifWidgetLoader(false));
      }, 1500);
    } else {
      put(notificationUrls().updateHighlight);
      setNewNotificationHighlight(false);
    }
    setOpenNotification(!openNotification);
  };

  const toggleNotificationPopup = () => {
    setOpenNotificationPopup(false);
    post(notificationUrls().notificationPopupClose);
  };

  const redirectToNotifCenter = () => {
    get(
      notificationUrls().notifCenterToken,
      (res:any) => { window.open(`${notificationUrls().notifCenterUrl}${res}`, '_blank'); return null; },
    );
  };
  const appBarRoutes = [
    appRoutes.dashboard,
    appRoutes.sentProjectDetails,
    appRoutes.FC,
    appRoutes.qualityCheck,
    appRoutes.reports,
    appRoutes.viewUnit,
    appRoutes.competitorAdmin,
  ];

  return (
    <div>
      {(appBarRoutes.includes(location.pathname) && isMobile === false && (isAuthenticated === true
    && (!isNullEmptyOrUndefined(userData()?.isOfUserType)
    && userTypesData.includes(userData()?.isOfUserType)))) ? (
      <header className={classes.headerContainer}>
        <NotificationWidget
          openNotification={openNotification}
          closeNotification={() => toggleNotification(false)}
          data={notificationData}
          loading={notifWidgetLoader}
          mediaToken={notificationMediaToken}
          notifCenter={redirectToNotifCenter}
        />
        <NotificationPopup
          isVisible={openNotificationPopup}
          onCloseModal={() => toggleNotificationPopup()}
          data={notificationPopupData}
          loading={notifPopupLoader}
          mediaToken={notificationMediaToken}
          notifCenter={redirectToNotifCenter}
        />
        <div
          className={classes.navBarContentWrapper}
        >
          <div className={classes.navBarLeftContainer}>
            <Hamburger showSuNewTag={showSuNewTag} />
            <div
              onClick={handleLogoClick}
              className={classes.toCompeteClickableLogo}
            >
              <img src={ikeaLogo} alt="" className={classes.toCompeteLogo} />
              <div className={`${defaultClasses.defaultFont} ${classes.toCompeteFont}`}>
                {applicationTitle}
              </div>
            </div>
          </div>
          <div className={classes.navBarRightContainer}>
            {qualityCheckCount > 0 && !isMobile && !isQcScreen ? (
              <div className={classes.qualityCheckWrapper} onClick={() => qualityClick()}>
                {`${qualityCheckCount} ${qualityIssues}`}
              </div>
            ) : null}
            <div
              className={classes.nameWrapper}
              tabIndex={0}
              role="button"
              onKeyDown={() => toggleSettings()}
              onClick={() => toggleSettings()}
            >
              <SSRIcon paths={IcPerson} />
              {userData()?.userName || 'User Name'}
            </div>
            <span>
              {newNotificationHighlight === true && <span id="navbar-widget-icon" className="navbar-widget-icon" />}
              <a onClick={() => toggleNotification(true)} className="navbar-widget-icon-notify" id="navbar-widget-icon-notify">
                <SSRIcon className="itemPointer" paths={Bell} />
              </a>
            </span>
          </div>
        </div>
      </header>
        ) : null}
      <SettingsPanel
        unit={unit}
        appVersion={appVersion}
        isVisible={openSettingsPanel}
        onClose={toggleSettings}
      />
    </div>

  );
};

export default AppBar;
