/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import '@ingka/switch/dist/style.css';
import '@ingka/forms/dist/style.css';
import '@ingka/focus/dist/style.css';
import './FindComparison.css';
import Filters from './filters/Filters';

const FindComparison = () => (
  <div className="findComparisonContainer">
    <Filters />
  </div>
);

export default FindComparison;
