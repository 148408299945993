/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import '@ingka/switch/dist/style.css';
import '@ingka/forms/dist/style.css';
import '@ingka/focus/dist/style.css';
import './CompetitorAdmin.css';
import CompetitorAdminFilter from './filters/CompetitorAdminFilter';
import AddCompetitorPopup from './addCompetitorPopup/AddCompetitorPopup';
import { userData } from '../../../common/services/commonService.js';
import useCallApi from '../../../common/services/apiService.js';
import { useAppDispatch } from '../../../store/hooks';
import { setLoader } from '../../../store/reducers/create-proj-reducer';

export type CompetitorProps = {
  competitorName: string
  competitorId?: string;
  status?: boolean;
}

const CompetitorAdmin = () => {
  const dispatch = useAppDispatch();
  const { post } = useCallApi();
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [competitorList, setCompetitorsList] = useState<CompetitorProps[]>([]);
  const [competitorListForAdd, setCompetitorsListForAdd] = useState<CompetitorProps[]>([]);

  const getCompetitorList = () => new Promise((resolve) => {
    const compPayload = {
      // TODO: Change 'GB' to dynamic RU code after userContext implementation
      retailUnitCode: 'GB',
      userId: userData()?.userId,
    };
    post('get-ru-competitors', compPayload, (res: any) => {
      setCompetitorsList(res || []);
      resolve(res);
    });
  });

  const getDropdownCompetitorsList = () => new Promise((resolve, reject) => {
    const compPayload = {
      // TODO: Change 'GB' to dynamic RU code after userContext implementation
      retailUnit: 'GB',
      userId: userData()?.userId,
    };
    post('get-non-ru-competitors', compPayload, (res: any) => {
      setCompetitorsListForAdd(res || []);
      resolve(res);
    }, () => {
      reject();
    });
  });

  const init = () => {
    dispatch(setLoader(true));
    Promise.all([
      // getCompetitorList(),
      getDropdownCompetitorsList(),
    ]).then(() => {
      dispatch(setLoader(false));
    }).catch(() => {
      dispatch(setLoader(false));
    });
  };

  useEffect(() => {
    init();
  }, []);

  const handleClose = (refresh: boolean) => {
    if (refresh === true) {
      init();
    }
    setOpenAddPopup(false);
  };

  return (
    <div className="competitorAdminContainer">
      <CompetitorAdminFilter
        competitorList={competitorList}
        onAddClick={() => setOpenAddPopup(true)}
      />
      <AddCompetitorPopup
        open={openAddPopup}
        competitorList={competitorListForAdd}
        onClose={(refresh: any) => handleClose(refresh)}
      />
    </div>
  );
};

export default CompetitorAdmin;
