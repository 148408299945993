/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/carousel/dist/style.css';
import '@ingka/tabs/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/tooltip/dist/style.css';
import Button from '@ingka/button';
import IcDetails from '@ingka/ssr-icon/paths/text';
import IcItems from '@ingka/ssr-icon/paths/copy';
import IcRU from '@ingka/ssr-icon/paths/location-pin';
import IcPublish from '@ingka/ssr-icon/paths/arrow-up-from-base';
import IcSave from '@ingka/ssr-icon/paths/document-checkmark';
import IcTrashCan from '@ingka/ssr-icon/paths/trash-can';
import SSRIcon from '@ingka/ssr-icon';
import Tooltip from '@ingka/tooltip';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import styles from './TopNavigation.module.css';
import { useTranslate } from '../../../../common/services/translationService.js';
import Items, { Item } from '../items/Items';
import DetailsTab from '../detailsTab/DetailsTab';
import RetailUnits, { RetailUnit } from '../retailUnitsTab/RetailUnits';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  createProj,
  createProjFullReset,
  prevProj, resetProj, setLoader,
  setPreviewOpen, setSaveValid, setSubmitValid, setToast,
} from '../../../../store/reducers/create-proj-reducer';
import SummaryPanel from '../summaryPanel/SummaryPanel';
import AppBarIcons from '../../../../common/components/appBarIcons/AppBarIcons';
import PreviewModal from '../preview/PreviewModal';
import useCallApi from '../../../../common/services/apiService.js';
import {
  isIos, isRu, projectType, userData, userRu, isNullEmptyOrUndefined, convertToBase64,
  compressJson,
} from '../../../../common/services/commonService.js';
import CloseModal from '../../../../common/components/closeModal/CloseModal';
import { appRoutes, projStatus } from '../../../../common/constants/constant';
import SellingUnits, { SellingUnit } from '../sellingUnitsTab/SellingUnits';
import { offlineData } from '../../../../common/configs/offlineData';

interface Counts {
  rucount: number;
  itemcount: number;
  suCount: number;
  isAccepted: boolean;
  onClick: () => void;
}
interface Project {
  detailsTab: Record<string, any>,
  itemsTab: { selectedItems: [] },
  ruTab: { selectedRu: [] }
}
const ButtonIcons = ({
  rucount, suCount, itemcount, isAccepted, onClick,
}: Counts) => (
  <Tooltip
    tooltipText="Preview button"
    describedById="tooltip-id-1"
    position="bottom"
    interactiveCustomTrigger
  >
    <div
      className={styles.buttoniconsWrapper}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <SSRIcon paths={IcItems} />
      <span className={styles.iconcount}>{itemcount}</span>
      <SSRIcon paths={IcRU} />
      <span className={styles.iconcount}>
        {(isIos() && isAccepted === false) ? rucount : suCount}
      </span>
    </div>
  </Tooltip>

);

const compareArray = (arr1: [], arr2: []) => {
  const isEqual = arr1?.length === arr2?.length
    && arr1?.every((value, index) => value === arr2[index]);
  return isEqual;
};

const compareArraySpecific = (arr1: [], arr2: any[], val:string) => {
  const isEqual = arr1?.length === arr2?.length
    && arr1?.every((value : any, index) => value[val] === arr2[index][val]);
  return isEqual;
};

export const compareProjects = (prevproj: any, projectStore: any) => {
  let isequal = JSON.stringify(prevproj?.detailsTab) === JSON.stringify(projectStore?.detailsTab);
  if (isequal) {
    isequal = compareArraySpecific(prevproj.itemsTab.selectedItems, projectStore.itemsTab.selectedItems, 'itemNo');
  }
  if (isequal) {
    isequal = compareArraySpecific(prevproj.ruTab.selectedRu, projectStore.ruTab.selectedRu, 'ruCode');
  }
  if (isequal) {
    isequal = compareArray(prevproj.suTab.selectedSu, projectStore.suTab.selectedSu);
  }
  if (isequal) {
    isequal = compareArray(prevproj.selectedSuItems, projectStore.selectedSuItems);
  }
  return isequal;
};

const Icons = ({
  ruCount, suCount, itemcount, isAccepted, isSubmitEnabled, isSaveEnabled,
  projectcloseClick, onCountClick, onSaveClick, onSubmitClick, onDeleteClick,
  isDraft, deleteTitle, isCopy,
}: {
  ruCount: number,
  suCount: number,
  itemcount: number,
  isAccepted: boolean,
  isSubmitEnabled: boolean,
  isSaveEnabled: boolean,
  projectcloseClick: () => void,
  onCountClick: () => void,
  onSaveClick: () => void,
  onSubmitClick: () => void,
  onDeleteClick: () => void,
  isDraft: boolean,
  deleteTitle: string,
  isCopy:boolean,
}) => (
  <div className={styles.iconsWrapper}>
    {(isDraft && isCopy === false) && (
      <Button
        className={styles.deleteBtn}
        text={deleteTitle}
        ssrIcon={IcTrashCan}
        type="secondary"
        onMouseDown={(e) => { e.preventDefault(); }}
        onClick={onDeleteClick}
        small
      />
    )}
    <Button
      className={styles.actionBtn}
      text={useTranslate('common.text.save')}
      ssrIcon={IcSave}
      type="secondary"
      onMouseDown={(e) => { e.preventDefault(); }}
      onClick={onSaveClick}
      disabled={isSaveEnabled !== true}
      small
    />
    <Button
      className={styles.actionBtn}
      text={useTranslate('common.text.submit')}
      type="primary"
      ssrIcon={IcPublish}
      disabled={isSubmitEnabled !== true}
      onClick={onSubmitClick}
      small
    />
    <ButtonIcons
      rucount={ruCount}
      suCount={suCount}
      itemcount={itemcount}
      isAccepted={isAccepted}
      onClick={onCountClick}
    />
    <AppBarIcons closeFn={projectcloseClick} />
  </div>
);

const saveApiCall = (
  projType:any,
  projDetails: any,
  projectStore:any,
  dispatch:any,
  postCompressedFile: any,
  type: string,
  endPoint: any,
  compressedContent: any,
  closeModal?: () => void,
  redirect?: any,
) => {
  const formData = new FormData();
  formData.append('gZipRequest', compressedContent);
  postCompressedFile(endPoint, formData, (res: any) => {
    dispatch(setLoader(false));
    if (type === 'submit') {
      closeModal && closeModal();
      dispatch(createProjFullReset());
      dispatch(setToast({ openToast: true, toastLabel: 'create.proj.submit_proj' }));
      redirect();
    } else {
      const projData = ({
        ...projectStore,
        detailsTab: {
          Name: projectStore?.detailsTab?.Name,
          StartDate: projectStore?.detailsTab?.StartDate,
          EndDate: projectStore?.detailsTab?.EndDate || null,
          Message: projectStore?.detailsTab?.Message || null,
          AdditionalMsg: projectStore?.detailsTab?.AdditionalMsg || '',
          ProjectId: res?.projectId || null,
        },
      });
      dispatch(createProj(projData));
      dispatch(prevProj(projData));
      if (projType === projStatus.accepted || projDetails?.hasParentProject === true) {
        dispatch(setToast({ openToast: true, toastLabel: 'create.proj.save_accepted' }));
      } else {
        dispatch(setToast({ openToast: true, toastLabel: 'create.proj.save_draft' }));
      }
    }
  }, (err: any) => {
    dispatch(setLoader(false));
    dispatch(setToast({ openToast: true, toastLabel: type === 'submit' ? 'create.proj.submit_proj_fail' : 'create.proj.save_draft_fail' }));
  });
};

export const saveRuProject = (
  projType:any,
  projDetails: any,
  projectStore:any,
  dispatch:any,
  postCompressedFile: any,
  type: string,
  closeModal?: () => void,
  redirect?: any,
) => {
  dispatch(setLoader(true));
  let endPoint = '';
  let payload = {};
  const suItemData = projectStore?.selectedSuItems?.filter((suItem: any) => suItem?.isActive === true);
  if (isRu() && (projType === projStatus.accepted || projDetails?.hasParentProject === true)) {
    endPoint = 'save-accepted-project';
    payload = {
      UserId: userData()?.userId,
      RuCode: userRu(),
      retailUnitProjectId: projDetails.RetailUnitProjectId || null,
      ItemNoActiveList: projectStore?.itemsTab?.selectedItems?.filter((item:Item) => item.isActive !== false && item.isRuItem !== 1).map(
        (val:Item) => ({ ItemNo: val.itemNo, ItemType: val.itemType }),
      ),
      ItemNoRuList: projectStore?.itemsTab?.selectedItems?.filter((item:Item) => item.isRuItem === 1).map(
        (val:Item) => ({ ItemNo: val.itemNo, ItemType: val.itemType }),
      ),
      SuCodeList: suItemData?.map((val:any) => (
        val.SuCode)),
      RetailUnitProjectBasic: {
        AdditionalMessage: convertToBase64(projectStore?.detailsTab?.AdditionalMsg) || null,
        EndDate: projectStore?.detailsTab?.EndDate,
        StatusNo: type === 'save' ? 10 : 20,
      },
      RupSplitSellingUnit: projectStore?.suTab?.selectedSu?.length > 0
        ? suItemData
        : [],
    };
    compressJson(payload).then((value) => {
      saveApiCall(
        projType,
        projDetails,
        projectStore,
        dispatch,
        postCompressedFile,
        type,
        endPoint,
        value,
        closeModal,
        redirect,
      );
    });
  } else {
    endPoint = 'save-ru-project';
    payload = {
      UserId: userData()?.userId,
      UnitCode: userRu(),
      ProjectId: projectStore?.detailsTab?.ProjectId || null,
      ItemList: projectStore?.itemsTab?.selectedItems.map((val:any) => (
        { ItemNo: val.itemNo, ItemType: val.itemType })),
      UnitList: suItemData?.map((val:any) => (
        { UnitCode: val.SuCode })),
      BasicInfo: {
        EndDate: projectStore?.detailsTab?.EndDate,
        Message: convertToBase64(projectStore?.detailsTab?.Message),
        ProjectName: `${userRu()}-${projectStore?.detailsTab?.Name}`,
        StartDate: projectStore?.detailsTab?.StartDate,
        StatusNo: type === 'save' ? 10 : 20,
        AdditionalMessage: null,
        ProjectId: projectStore?.detailsTab?.ProjectId || null,
      },
      RupSplitSellingUnit: projectStore?.suTab?.selectedSu?.length > 0
        ? suItemData
        : [],
    };
    compressJson(payload).then((value) => {
      saveApiCall(
        projType,
        projDetails,
        projectStore,
        dispatch,
        postCompressedFile,
        type,
        endPoint,
        value,
        closeModal,
        redirect,
      );
    });
  }
};

// eslint-disable-next-line max-len
export const saveDraftProject = (projectStore: any, dispatch: any, post: any, prevItemsData?:any, type?: string, redirect?: any) => {
  dispatch(setLoader(true));
  const savePayload = {
    GProjectDetails: {
      GProjectId: projectStore?.detailsTab?.ProjectId,
      GProjectName: `IoS-${projectStore?.detailsTab?.Name}`,
      UserId: userData()?.userId,
      StatusNo: type ? 20 : 10,
      StartDate: projectStore?.detailsTab?.StartDate,
      EndDate: projectStore?.detailsTab?.EndDate,
      Message: convertToBase64(projectStore?.detailsTab?.Message),
    },
    GProjectId: projectStore?.detailsTab?.ProjectId,
    // GProjectItems: type ? projectStore?.itemsTab?.selectedItems.map((val: any) => (
    //   {
    //     ItemNo: val.itemNo, ItemType: val.itemType, PaNo: val.paNo, ItemName: val.ItemName,
    //   })) : [],
    // GProjectRetailUnits: type ? projectStore?.ruTab?.selectedRu?.map((val: any) => (
    //   { RuCode: val.ruCode, RuName: val.ruName, RuImage: val.ruImage })) : [],
    // ItemNoList: projectStore?.itemsTab?.selectedItems.map((val: any) => (
    ItemNoList: (!isNullEmptyOrUndefined(prevItemsData) ? prevItemsData : projectStore?.itemsTab?.selectedItems)?.map((val: any) => (
      { ItemNo: val.itemNo, ItemType: val.itemType })),
    RuCodeList: projectStore?.ruTab?.selectedRu?.map((val: any) => val.ruCode),
  };
  post(
    'Save-Project',
    savePayload,
    (res: any) => {
      dispatch(setLoader(false));
      if (type) {
        dispatch(createProjFullReset());
        dispatch(setToast({ openToast: true, toastLabel: 'create.proj.submit_proj' }));
        redirect();
      } else {
        const projData = ({
          ...projectStore,
          detailsTab: {
            Name: projectStore?.detailsTab?.Name,
            StartDate: projectStore?.detailsTab?.StartDate,
            EndDate: projectStore?.detailsTab?.EndDate || null,
            Message: projectStore?.detailsTab?.Message || null,
            AdditionalMsg: projectStore?.detailsTab?.AdditionalMsg || '',
            ProjectId: res?.projectId || null,
          },
        });
        dispatch(createProj(projData));
        dispatch(prevProj(projData));
        dispatch(setToast({ openToast: true, toastLabel: 'create.proj.save_draft' }));
      }
    },
    (error: any) => {
      dispatch(setLoader(false));
      dispatch(setToast({
        openToast: true,
        toastLabel: type
          ? 'create.proj.submit_proj_fail' : 'create.proj.save_draft_fail',
      }));
    },
  );
};

const TopNavigation = () => {
  const [rucount, setRucount] = useState<number>(0);
  const [totalRucount, setTotalRuCount] = useState<number>(0);
  const [suCount, setSuCount] = useState<number>(0);
  const [itemcount, setItemcount] = useState<number>(0);
  const [openPrompt, setOpenPrompt] = useState<boolean>(false);
  const projectStore = useAppSelector((state) => state.createProjReducer.createProjData);
  const prevProjectStore = useAppSelector((state) => state.createProjReducer.prevProjData);
  const previewOpen = useAppSelector((state) => state.createProjReducer.previewOpen);
  const nameValid = useAppSelector((state) => state.createProjReducer.nameValid);
  const messageValid = useAppSelector((state) => state.createProjReducer.messageValid);
  const addtMessageValid = useAppSelector((state) => state.createProjReducer.addtMessageValid);
  const loader = useAppSelector((state) => state.createProjReducer.isLoading);
  const saveValid = useAppSelector((state) => state?.createProjReducer?.saveValid);
  const submitValid = useAppSelector((state) => state?.createProjReducer?.submitValid);
  const activeAcceptedItems = projectStore?.itemsTab?.selectedItems?.filter((item: any) => item?.isActive === true);
  const [openSummaryPanel, setOpenSummaryPanel] = useState(false);
  const SU_LABEL = useTranslate('tab.sellingUnits');
  const RU_LABEL = useTranslate('tab.retailUnits');
  const projDeleteTitle = useTranslate('create.proj.delete');
  const projectDeleteError = useTranslate('project.delete.error');
  const projectDeleteSuccess = useTranslate('project.delete.success');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { post, postCompressedFile } = useCallApi();
  const location: any = useLocation();
  const [openDeleteProjModal, setOpenDeleteProjModal] = useState<boolean>(false);
  const projtType = location?.state?.type;
  const projtData = location?.state?.projData;
  const isEditProject = location?.state?.isEditProject;
  const isNewProject = location?.state?.isNewProject;
  const isCopy = location?.state?.isCopy || false;

  const getProjectDetails = () => {
    if (isIos()) {
      return projtData?.gProjectDetails;
    }
    if (isRu() && (projtType === projStatus.accepted || isEditProject)) {
      return projtData?.viewCProjectDetails;
    }
    return projtData?.basicInfo;
  };

  const getItemDetails = () => {
    if (isIos()) {
      return projtData?.gProjectItems;
    }
    if (isRu() && (projtType === projStatus.accepted || isEditProject)) {
      return projtData?.viewCProjectItems;
    }
    return projtData?.itemList;
  };

  const projDetails = getProjectDetails();
  const itemDetails = getItemDetails();
  const ruDetails: RetailUnit[] = [];
  projtData?.gProjectRetailUnits?.forEach((ru: any) => {
    const ruInfo: RetailUnit = {
      key: ru.GProjectId,
      ruName: ru.RuName,
      ruCode: ru.RuCode,
      ruImage: ru.ruImage,
    };
    ruDetails.push(ruInfo);
  });

  const redirectDashboard = () => {
    navigate(
      appRoutes.dashboard,
      {
        state: {
          dashboardFilters: true,
        },
      },
    );
  };

  const isAcceptedRDraftProj = () => {
    if (projtType === projStatus.accepted) {
      return true;
    }
    if (projtType === projStatus.draft) {
      return true;
    }
    if (isEditProject === true) {
      return true;
    }
    return false;
  };

  const isRuOrAccepted = () => {
    if (isRu() || projtType === projStatus.accepted) {
      return true;
    }
    return false;
  };

  const getProjectId = () => {
    if (isRu() && projtType === projStatus.draft) {
      return projDetails?.projectId;
    }
    if (isRu() && (projtType === projStatus.accepted || isEditProject === true)) {
      return projDetails?.RetailUnitProjectId;
    }
    return projDetails?.GProjectId;
  };

  const acceptedSuDetails: SellingUnit[] = [];
  projtData?.sellingUnitViewDto?.forEach((su: any) => {
    const suInfo: SellingUnit = {
      key: getProjectId(),
      unitName: su.SuName,
      parentUnitCode: su.RuCode,
      unitCode: su.SuCode,
      isAdded: su.isAdded,
    };
    acceptedSuDetails.push(suInfo);
  });

  const selectedAcceptedSU: SellingUnit[] = acceptedSuDetails.filter((su: any) => su.isAdded === true);

  const getProjectName = () => {
    if (isIos()) {
      return projDetails?.GProjectName;
    }
    if (isRu() && (projtType === projStatus.accepted || isEditProject === true)) {
      return projDetails?.RetailUnitProjectName;
    }
    return projDetails?.projectName;
  };

  const suDetails: SellingUnit[] = [];
  projtData?.unitList?.forEach((su: any) => {
    const suInfo: SellingUnit = {
      key: getProjectId(),
      unitName: su.unitName,
      parentUnitCode: su.parentUnitCode,
      unitCode: su.unitCode,
      isAdded: su.isAdded,
    };
    suDetails.push(suInfo);
  });
  const selectedSUDetails: SellingUnit[] = suDetails.filter((su: any) => su.isAdded === true);

  const presetProjData = () => {
    const projData = ({
      ...projectStore,
      detailsTab: {
        Name: getProjectName(),
        StartDate: projDetails?.projectStartDate,
        EndDate: projDetails?.projectEndDate,
        Message: projDetails?.message,
        AdditionalMsg: projDetails?.additionalMessage || '',
        ProjectId: getProjectId() || null,
      },
      itemsTab: { selectedItems: itemDetails },
      ruTab: { selectedRu: ruDetails },
      suTab: { selectedSu: isEditProject === true ? selectedAcceptedSU : selectedSUDetails },
    });
    dispatch(createProj(projData));
    dispatch(prevProj(projData));
  };
  const openModal = () => {
    const isEqual = compareProjects(prevProjectStore, projectStore);
    if (isEqual) {
      redirectDashboard();
      dispatch(createProjFullReset());
    } else {
      setOpenPrompt(true);
    }
  };

  const closeModal = (type = 0) => {
    switch (type) {
      case 1:
        redirectDashboard();
        dispatch(createProjFullReset());
        break;
      case 2:
        if (isRu()) {
          let projDataSubmit = [];
          if (isCopy && !isIos()) {
            projDataSubmit = ({ ...projectStore, itemsTab: { selectedItems: activeAcceptedItems } });
            dispatch(createProj(projDataSubmit));
          }
          const prjStrSubmit = (isCopy && !isIos()) ? projDataSubmit : projectStore;
          saveRuProject(projtType, projDetails, prjStrSubmit, dispatch, postCompressedFile, 'save');
        } else { saveDraftProject(projectStore, dispatch, post); }
        redirectDashboard();
        dispatch(resetProj());
        break;
      default:
        break;
    }
    setOpenPrompt(false);
  };

  const openDeleteModal = () => {
    setOpenDeleteProjModal(true);
  };

  /**
 * deleteDraftProject makes a POST request to delete a project.
 * It dispatches actions to show a loader, make the API call, and then hide the loader.
 * If the API call is successful, it dispatches an action to show a success toast and resolves the Promise.
 * If the API call fails, it dispatches an action to show an error toast and rejects the Promise.
 * @returns {Promise<void>} A Promise that resolves if the API call is successful and rejects if it fails.
 */
  const deleteDraftProject = (): Promise<void> => new Promise((resolve, reject) => {
    dispatch(setLoader(true));
    const deletePayload: any = {
      ProjectId: getProjectId(),
      ProjectType: projectType(),
      StatusNo: projDetails?.statusNo,
    };
    post(
      'project-delete',
      deletePayload,
      () => {
        dispatch(setLoader(false));
        dispatch(
          setToast({
            openToast: true,
            toastLabel: projectDeleteSuccess,
          }),
        );
        resolve();
      },
      () => {
        dispatch(setLoader(false));
        dispatch(
          setToast({ openToast: true, toastLabel: projectDeleteError }),
        );
        reject();
      },
    );
  });

  /**
 * deleteModal is an async function that handles the deletion of a project.
 * If the type parameter is 2, it calls deleteDraftProject and waits for it to complete.
 * If deleteDraftProject is successful, it dispatches an action to reset the project.
 * Regardless of whether deleteDraftProject is successful or fails, it calls redirectDashboard.
 * If the type parameter is not 2, it dispatches an action to close the delete project modal.
 * @param {number} [type] - The type of deletion to perform. If this is 2, the function will attempt to delete the project.
 * @returns {Promise<void>} A Promise that resolves when the function has completed.
 */
  const deleteModal = async (type?: number) => {
    try {
      if (type === 2) {
        await deleteDraftProject();
        dispatch(resetProj());
      } else {
        setOpenDeleteProjModal(false);
      }
    } catch (error) {
      // DO nothing
    } finally {
      redirectDashboard();
    }
  };

  const toggleSidePanel = () => {
    setOpenSummaryPanel(!openSummaryPanel);
  };

  const togglePreviewModal = () => {
    dispatch(setPreviewOpen(!previewOpen));
  };

  const getSellingUnits = () => (
    <SellingUnits
      projectId={getProjectId()}
      retailUnitProjectID={projtData?.itemList[0]?.projectId || ''}
      suDetails={suDetails}
      projtType={projtType}
      itemDetails={itemDetails}
      isEditProject={isEditProject}
    />
  );

  const getAcceptedSellingUnits = () => (
    <SellingUnits
      projectId={getProjectId()}
      suDetails={acceptedSuDetails}
      projtType={projtType}
      itemDetails={itemDetails}
      isEditProject={isEditProject}
    />
  );

  const onSuTabClick = () => {
    dispatch(setLoader(true));
    setTimeout(() => {
      dispatch(setLoader(false));
    }, 200);
  };

  const tabs = [
    <Tab
      key="tab-1"
      text={useTranslate('tab.details')}
      tabPanelId="tab_1"
      ssrIcon={IcDetails}
    />,
    <Tab
      key="tab-2"
      text={useTranslate('tab.items')}
      active
      tabPanelId="tab_2"
      ssrIcon={IcItems}
    />,
    <Tab
      key="tab-3"
      text={isRuOrAccepted() === true ? SU_LABEL : RU_LABEL}
      tabPanelId="tab_3"
      ssrIcon={IcRU}
      onClick={onSuTabClick}
    />,
  ];

  const tabPanels = [
    <TabPanel
      key="tab-1"
      tabPanelId="tab_1"
    >
      <DetailsTab projtDetails={projDetails} isCopy={isCopy} isEditProject={isEditProject} />
    </TabPanel>,
    <TabPanel
      className={`${styles.tabPanel} ${styles.itemsTabPanel}`}
      key="tab-2"
      tabPanelId="tab_2"
    >
      <Items
        itemDetails={itemDetails}
        projtType={projtType}
        projDetails={projDetails}
        isEditProject={isEditProject}
        isNewProject={isNewProject}
      />
    </TabPanel>,
    <TabPanel
      key="tab-3"
      tabPanelId="tab_3"
    >
      {isRuOrAccepted() ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          { ((projDetails?.hasParentProject === true || projDetails?.hasParentProject === false) && !(projtType === projStatus.draft))
            ? getAcceptedSellingUnits() : getSellingUnits() }
        </>
      )
        : (
          <RetailUnits
            ruDetails={ruDetails}
            projtType={projtType}
            projDetails={projDetails}
            itemDetails={itemDetails}
            isEditProject={isEditProject}
          />
        )}
    </TabPanel>,
  ];

  const isValidStartDate = (date: any) => {
    if (date === dayjs().format('YYYY-MM-DD')) {
      return true;
    } if (dayjs() > dayjs(date)) {
      return false;
    }
    return true;
  };

  const isValidEndDate = (startDate: any, endDate: any) => {
    if (!isNullEmptyOrUndefined(startDate) && isNullEmptyOrUndefined(endDate)) {
      return true;
    } if (startDate < endDate) {
      return true;
    }
    return false;
  };

  const checkStartDate = (startDate: any) => {
    if (projtType === projStatus.draft) {
      return isValidStartDate(startDate);
    }
    return startDate;
  };

  const isRUCheckedSellingUnit = (rulength: any, sulength: any) => {
    if (isRu()) {
      if ((projtType === projStatus.accepted) && !compareProjects(prevProjectStore, projectStore)) {
        return true;
      } if (projtType !== projStatus.send || projtType !== projStatus.closed) {
        return true;
      }
    }
    return (rulength !== 0 || sulength !== 0);
  };

  useEffect(() => {
    const rulength = projectStore?.ruTab?.selectedRu?.length || 0;
    const sulength = projectStore?.suTab?.selectedSu?.length || 0;
    const itemlength = projectStore?.itemsTab?.selectedItems?.length || 0;
    const activeItems = projectStore?.itemsTab?.selectedItems?.filter((item: any) => item?.isActive === true);
    const acceptedPrjItems = projectStore?.itemsTab?.selectedItems?.filter((item: any) => item?.isActive === true || item?.isRuItem === 1);
    const acceptedPrjItemslength = acceptedPrjItems?.length || 0;
    const activeItemslength = activeItems?.length || 0;
    const nameLength = projectStore?.detailsTab?.Name?.length || 0;
    const startDate: Dayjs | null = projectStore?.detailsTab?.StartDate || null;
    const endDate: Dayjs | null = projectStore?.detailsTab?.EndDate || null;

    setTimeout(() => {
      if ((nameLength !== 0
      && startDate && !isNullEmptyOrUndefined(startDate) && checkStartDate(startDate)
      && endDate && !isNullEmptyOrUndefined(endDate) && startDate < endDate
      && itemlength !== 0
      && isRUCheckedSellingUnit(rulength, sulength)
      && nameValid.status === true
      && messageValid === true
      && addtMessageValid === true
      ) || ((isEditProject === true || projDetails?.hasParentProject) && addtMessageValid === true)) {
        dispatch(setSubmitValid(true));
      } else {
        dispatch(setSubmitValid(false));
      }
      dispatch(setSaveValid(
        ((nameLength !== 0
        && nameValid.status === true
        && messageValid === true
        && addtMessageValid === true
        && !compareProjects(prevProjectStore, projectStore)
        && (projtType === projStatus.accepted || (!isNullEmptyOrUndefined(startDate) && isValidStartDate(startDate)))
        && (projtType === projStatus.accepted || isValidEndDate(startDate, endDate)))
        )
      && !isEditProject === true,
      ));
    }, 500);
    if (isCopy && !isIos()) {
      setItemcount(activeItemslength);
    } else if (isIos()) {
      setItemcount(itemlength);
    } else if (isRu() && projtType === projStatus.draft) {
      setItemcount(itemlength);
    } else if ((isRu() && (projtType === projStatus.accepted || projDetails?.hasParentProject))) {
      setItemcount(acceptedPrjItemslength);
    } else if (isRu() && projDetails?.hasParentProject === false) {
      setItemcount(itemlength);
    } else {
      setItemcount(itemlength);
    }
    setRucount(rulength);
    setSuCount(sulength);
  }, [JSON.parse(JSON.stringify(projectStore)), nameValid, projtData]);

  useEffect(() => {
    isAcceptedRDraftProj() && presetProjData();
    offlineData.getItem('retailUnits').then((ruList: any) => {
      ruList?.length > 0 && setTotalRuCount(ruList.length);
    });
  }, []);

  return (

    <div className={styles.parent} style={{ pointerEvents: loader ? 'none' : 'all' }}>
      <Tabs className={styles.tabsContainer} tabs={tabs} tabPanels={tabPanels} defaultActiveTab="tab_1" />
      <Icons
        ruCount={rucount}
        suCount={suCount}
        itemcount={itemcount}
        isSubmitEnabled={submitValid}
        isSaveEnabled={saveValid}
        projectcloseClick={openModal}
        isCopy={isCopy}
        isAccepted={projtType === projStatus.accepted}
        onCountClick={toggleSidePanel}
        onSaveClick={() => {
          if (isRu()) {
            let projDataSubmit = [];
            if (isCopy && !isIos()) {
              projDataSubmit = ({ ...projectStore, itemsTab: { selectedItems: activeAcceptedItems } });
              dispatch(createProj(projDataSubmit));
            }
            const prjStrSubmit = (isCopy && !isIos()) ? projDataSubmit : projectStore;
            saveRuProject(projtType, projDetails, prjStrSubmit, dispatch, postCompressedFile, 'save');
          } else { saveDraftProject(projectStore, dispatch, post); }
        }}
        onSubmitClick={togglePreviewModal}
        onDeleteClick={openDeleteModal}
        isDraft={projtType === projStatus.draft}
        deleteTitle={projDeleteTitle}
      />
      <CloseModal
        modelOpen={openPrompt}
        closeModal={closeModal}
        saveValid={saveValid}
        closeText={useTranslate('create.proj.closepopup_closetext')}
        saveText={useTranslate('create.proj.closepopup_savetext')}
        title={useTranslate('create.proj.closepopup_title')}
        subText={useTranslate('create.proj.closepopup_subtext')}
      />
      <SummaryPanel
        isVisible={openSummaryPanel}
        onClose={toggleSidePanel}
        projDetails={projDetails}
        isEditProject={isEditProject}
        isCopy={isCopy}
      />
      <PreviewModal
        isCopy={isCopy}
        isVisible={previewOpen}
        onClose={togglePreviewModal}
        projtType={projtType}
        projDetails={projDetails}
        ruCount={totalRucount}
        isEditProject={isEditProject}
        itemDetails={itemDetails}
        ruDetails={ruDetails}
        suDetails={isEditProject === true ? selectedAcceptedSU : selectedSUDetails}
      />

      <CloseModal
        modelOpen={openDeleteProjModal}
        closeModal={deleteModal}
        saveValid
        closeText={useTranslate('proj.details.delete.confirmation.negetive')}
        saveText={useTranslate('create.proj.deleteModal_delete')}
        title={useTranslate('common.confirmation.title')}
        subText={useTranslate('create.proj.deleteModal_subtext')}
      />
    </div>
  );
};

export default TopNavigation;
