/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Modal, { ModalBody, ModalHeader, Theatre } from '@ingka/modal';
import './AddCompetitorPopup.css';
import { Checkbox, Select } from 'antd';
import Text from '@ingka/text';
import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import IcBullet from '@ingka/ssr-icon/paths/bullet';
import { useTranslate } from '../../../../common/services/translationService.js';
import { isNullEmptyOrUndefined, userData } from '../../../../common/services/commonService.js';
import { CompetitorProps } from '../CompetitorAdmin';
import useCallApi from '../../../../common/services/apiService.js';
import { useAppDispatch } from '../../../../store/hooks';
import { setLoader, setToast } from '../../../../store/reducers/create-proj-reducer';

interface AddCompetitorPopupProps {
  open: boolean;
  competitorList: CompetitorProps[];
  onClose: (refresh?: boolean) => void;
}

const AddCompetitorPopup = ({
  open, competitorList, onClose,
}: AddCompetitorPopupProps) => {
  const { post } = useCallApi();
  const dispatch = useAppDispatch();
  const [selectedCompetitorId, setSelectedCompetitorId] = useState<number | string>();
  const [searchCompValue, setSearchCompValue] = useState('');
  const [selectedCompetitor, setSelectedCompetitor] = useState<string | number>();
  const [error, setError] = useState<{isError: boolean, message: string}>({ isError: false, message: '' });
  const txtAddCompetitor = useTranslate('compAdmin.addComp.title');
  const guidelineLabel = useTranslate('compAdmin.addComp.guideline.label');
  const guidelineTitle = useTranslate('compAdmin.addComp.guideline.title');
  const successSave = useTranslate('compAdmin.addComp.success');

  const guidelines = [
    useTranslate('compAdmin.addComp.guideline.one'),
    useTranslate('compAdmin.addComp.guideline.two'),
    useTranslate('compAdmin.addComp.guideline.three'),
  ];

  const onClosePopup = (refresh?: boolean) => {
    setSelectedCompetitorId('');
    setSelectedCompetitor('');
    setError({ isError: false, message: '' });
    onClose(refresh);
  };

  const setCompetitor = (value: string | number, name: string | number) => {
    setError({ isError: false, message: '' });
    setSelectedCompetitorId(value);
    setSelectedCompetitor(name);
  };

  const isNewCompetitor = !isNullEmptyOrUndefined(selectedCompetitor) && isNullEmptyOrUndefined(selectedCompetitorId);

  const addNewComp = (
    <div className="acpAddAsNew">
      <Checkbox
        id="addNewComp"
        name="addNewComp"
        onChange={() => setCompetitor('', searchCompValue)}
        data-testid="add-new-competitor-checkbox"
      >
        {`${useTranslate('compAdmin.addComp.addAsNew.one')} “${searchCompValue}” ${useTranslate('compAdmin.addComp.addAsNew.two')}`}
      </Checkbox>
    </div>
  );

  const disableAddButton = isNullEmptyOrUndefined(selectedCompetitor) || error?.isError;

  const submitCompetitor = () => {
    dispatch(setLoader(true));
    let compPayload: any = {
      userId: userData()?.userId,
      // TODO: Change after userContext integration
      ruCode: 'GB',
      // ruCode: userRu(),
    };
    if (!isNullEmptyOrUndefined(selectedCompetitorId)) {
      compPayload = {
        ...compPayload,
        competitorId: selectedCompetitorId,
      };
    } else {
      compPayload = {
        ...compPayload,
        competitorName: selectedCompetitor,
      };
    }
    post('save-competitor', compPayload, (res:any) => {
      setTimeout(() => {
        dispatch(setLoader(false));
        if (res?.isSuccess === true) {
          dispatch(setToast({ openToast: true, toastLabel: successSave }));
          onClosePopup(true);
        } else {
          setError({ isError: true, message: res?.message });
        }
      }, 500);
    });
  };

  const renderLabel = () => {
    if (error?.isError) {
      return <Text className="acpError">{error?.message}</Text>;
    }
    if (isNewCompetitor) {
      return <Text bodySize="s" className="acpLabel">{guidelineLabel}</Text>;
    }
    return null;
  };

  return (
    <Modal
      className="acpModal"
      handleCloseBtn={onClosePopup}
      visible={open}
      escapable={false}
    >
      <Theatre
        aria-labelledby="addCompModal"
        header={(
          <ModalHeader
            titleId="addCompTitle"
            title={txtAddCompetitor}
            floating={false}
          />
      )}
      >
        <ModalBody className="acpModalBody">
          <div className="acpBodyInnerWrapper">
            <Text className="acpInputLabel">{useTranslate('compAdmin.addComp.inputLabel')}</Text>
            <Select
              showSearch
              getPopupContainer={(trigger) => trigger.parentNode}
              className="acpDropdown"
              options={competitorList || []}
              fieldNames={{
                label: 'competitorName',
                value: 'competitorName',
              }}
              autoClearSearchValue={false}
              defaultOpen={false}
              popupClassName="competitorDropdown"
              defaultActiveFirstOption={false}
              value={selectedCompetitor as any}
              defaultValue={selectedCompetitor as any}
              disabled={false}
              onSelect={(val: any, option: any) => setCompetitor(option.competitorId, option.competitorName)}
              onSearch={(val: any) => {
                setSearchCompValue(val);
              }}
              filterOption={(input, option) => {
                if (!option || !option.competitorName) return false;
                return option.competitorName.toLowerCase().includes(input.toLowerCase());
              }}
              dropdownRender={(menu) => (menu)}
              notFoundContent={addNewComp}
              data-testid="competitor-select"
              suffixIcon={null}
            />
            {renderLabel()}
            <div className="acpGuidelinesContainer">
              {isNewCompetitor ? (
                <div className="acpGuidelinesWrapper">
                  <Text className="acpGuidelineTitle" headingSize="xs">{guidelineTitle}</Text>
                  {guidelines.map((guideline) => (
                    <div className="acpGuidelineWrapper">
                      <SSRIcon className="acpBullet" paths={IcBullet} />
                      <Text className="acpGuideline">{guideline}</Text>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <Button
              className="acpAddButton"
              text={useTranslate('compAdmin.addComp.title')}
              type="emphasised"
              disabled={disableAddButton}
              // onClick={submitCompetitor}
            />
          </div>
        </ModalBody>
      </Theatre>
    </Modal>
  );
};

export default AddCompetitorPopup;
